import InputCvFile from "components/Inputs/InputCvFile";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { showAddCv } from "redux/reducer/addCvReducer";

const AddCv = () => {
  const { t } = useTranslation('site');
  const dispatch = useDispatch();
    
    return (
        <div className="flex relative">
            <button className="absolute top-0 right-0" onClick={()=>dispatch(showAddCv(false))}><i className="fa fa-times"></i></button>
            <InputCvFile />
            {/*<div className="w-1/3 py-10">*/}
            {/*    <p className="text-center text-2xl font-light leading-10">{t('create_new_cv')}?</p>*/}
            {/*    <div className="flex mt-16 items-center ">*/}
            {/*        <p className="text-xl">{t('or')}</p>*/}
            {/*        <Link*/}
            {/*            className={` mx-auto block text-center bg-lightBlue-500 text-white active:bg-lightBlue-600 font-semibold uppercase text-lg  py-6 px-6  rounded shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 `}*/}
            {/*            to="/cv"*/}
            {/*        >*/}
            {/*            {t('create_new_cv')}*/}
            {/*        </Link>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    )
}

export default AddCv;