import axiosClient from "api/axiosClient";
import Info from "components/Modals/Info";
import UploadCV from "components/Modals/UploadCV"
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setCvRubricationData } from "redux/reducer/searchReducer";
import { setShowLoader } from "redux/reducer/searchReducer";
import { setTrainingRubricationData } from "redux/reducer/searchReducer";
import { setJobRubricationData } from "redux/reducer/searchReducer";
import { setTrainingId } from "redux/reducer/searchReducer";
import { setJobId } from "redux/reducer/searchReducer";
import { setCvId } from "redux/reducer/searchReducer";
import { addTrainingList } from "redux/reducer/trainingListReducer";
import Loading from '../../Loadings/Loading';


const SelectTraining = () => {
    const {t,i18n}=useTranslation('site');
    let lang = i18n.language;
    lang = i18n.language.substring(0, 2).toUpperCase();
    const [showTrainingLoader, setShowTrainingLoader] = useState(false);

    const dispatch = useDispatch();
    let {
        cvId,
        jobId,
        trainingId
    } = useSelector(state => state.searchInput, shallowEqual);
    const trainingList = useSelector(state => state.trainingList, shallowEqual);
    const updatedCvs = useSelector(state => state.updatedCvs, shallowEqual);
    
    const selectTrainingHandler = (e) => {
        const selectedTrainingId = e.target.value;
        dispatch(setTrainingId(selectedTrainingId));

        getTrainingRubrication(selectedTrainingId);

    }

    const getTrainingRubrication = (trainingId) => {
        if (trainingId) {
            dispatch(setShowLoader(true));
            axiosClient.get(`/Rubrication/GetTrainingRubricationById?trainingId=${trainingId}`).then(res => {
                if (res.status === 200) {
                    const { rubrication_items } = res.data;
                    dispatch(setTrainingRubricationData(rubrication_items));
                }
                dispatch(setShowLoader(false));
            }).catch(err=>dispatch(setShowLoader(false))); 
        } else {
            dispatch(setTrainingRubricationData([]));
        }
        
        
    }

    const handleTrainingSubmit = () => {
        
        trainingMatchingHandler(cvId, jobId);
        
    }
    const trainingMatchingHandler = (cvId,jobId) => {
        
        
        findTrainings(cvId,jobId);
    }

    const findTrainings = (cvId, jobId) => {
        
        dispatch(setShowLoader(true));
        setShowTrainingLoader(true)
        const updatedCv = updatedCvs.find(d => d.uniqueId === cvId + jobId);
        const plusText = updatedCv ? updatedCv.plusText : '';
        console.log({plusText})
        const body = {
            "ref_profile_id": "",
            "exclude_pooled_items": true,
            "selected_cv_id": jobId,
            "selected_job_id": cvId,
            "pre_selection": {
              "cost_expense": 50000,
              "time_expense": 50000,
              "training_filter": "NotFiltered",
                "affiliation": "NotFiltered",
              "plus_text": plusText
            },
            "languages": [
                "FR","DE","EN"
            ],
            "language": lang,
            "fallback_language": "DE"
          };
        axiosClient.post(`/Matching/GetTrainingMatchByPreselection`, body).then(res => {
            if (res.status === 200) {

                if (res.data && res.data.length) {
                    // let trainings = {
                    //     cvId,
                    //     jobId,
                    //     trainingList: []
                    // };
                    let trainings = [];
                    let firstTrainingId = '';

                    res.data.map((data,index) => {
                        const { title, company, target_profile_id, ranking_score, city, position } = data;
                        const training = {
                            title,
                            company,
                            target_profile_id,
                            ranking_score,
                            city,
                            position
                        }
                        // trainings.trainingList.push(training);
                        trainings.push(training);
                        if (index === 0) {
                            firstTrainingId = target_profile_id;
                        } 
                    })
                    dispatch(addTrainingList(trainings));
                    dispatch(setTrainingId(firstTrainingId));
                    
                    setShowTrainingLoader(false);
                    const select_training = document.querySelector('#training_list');
                    select_training.value = firstTrainingId;
                    select_training.dispatchEvent(new Event('change', { bubbles: true }));
                } 
            } else {
                dispatch(setShowLoader(false));
                setShowTrainingLoader(false);
            }
        }).catch(err => {
            dispatch(setShowLoader(false));
            setShowTrainingLoader(false);
        }).finally(() => {
            dispatch(setShowLoader(false));
            setShowTrainingLoader(false);
        });
    }

    useEffect(() => {
        trainingMatchingHandler(cvId, jobId);
    }, []);
    return (
        <>
            <div className="flex relative">
            <Info cssClasses="absolute right-0 top-2" msg="select_training_info" />
                {
                    showTrainingLoader ? <Loading className="mx-auto" /> :
                        (<div className=" px-3">
                            <label htmlFor="training_list" className="font-semibold flex gap-1">{t('Choose_a_training')}{showTrainingLoader && <Loading />}</label><br />
                            <div className="flex w-full">
                                <select
                                    id="training_list"
                                    className="w-8/12 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline w-full min-w-140-px max-w-full"
                                    onChange={selectTrainingHandler}
                                    value={trainingId}
                                >
                                    <option value=""></option>
                                    
                                    {trainingList && trainingList.length > 0 && trainingList.map((training, index) => <option key={index} value={training.target_profile_id}>{training.title}</option>)}
                                </select>    
                                <Link
                                    to={`/training-details?id=${trainingId}`}
                                    className=" w-4/12 bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-sm px-3 py-3  rounded shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 ml-5 text-center"
                                >
                                    {t('show_details')}
                                </Link>
                            </div>
                        </div>)
                }
                        
                        
                        {/* <button
                            className="w-4/12 bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-sm px-3 py-3  rounded shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 mt-5"
                            onClick={handleTrainingSubmit}
                        >
                            <i className="fas fa-search"></i> {t('FIND_TRAINING_Btn')}
                        </button> */}
                    </div>     
        </>
    )
}
export default SelectTraining;