import axiosClient from "api/axiosClient";
import Info from "components/Modals/Info";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { addJob } from "redux/reducer/jobListReducer";
import { addJobOptions } from "redux/reducer/jobOptionsReducer";
import { setShowLoader } from "redux/reducer/searchReducer";
import { setJobId } from "redux/reducer/searchReducer";
import { setJobRubricationData } from "redux/reducer/searchReducer";
import { setTrainingRubricationData } from "redux/reducer/searchReducer";
import { setTrainingId } from "redux/reducer/searchReducer";
import { setJobTitle } from "redux/reducer/searchReducer";
import { setCvRubricationData } from "redux/reducer/searchReducer";
import translateRubrications from "utils/translateRubrications";
import axiosClientNode from "../../api/axiosClientNode";


const ActualQualifications = ({canUpdate}) => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation('site');
    const lang = i18n.language.substring(0, 2);
    let { resultNumber, cvRubricationData, cvId, jobId } = useSelector(state => state.searchInput);

    const [cvRubricationsState, setCvRubricationsState] = useState(cvRubricationData)


    const translatedCvRubrications = translateRubrications(cvRubricationsState,lang);
    const sortedCvRubrications = translatedCvRubrications.sort((a, b) => b.ranking_score - a.ranking_score);

    const cvQualifications = resultNumber > 0 ? sortedCvRubrications.slice(0, 10) : sortedCvRubrications;

    const handleSelectQualification = (qualification, checked) => {
        const updatedQualifications = [{...qualification, checked}, ...cvRubricationsState.filter(item => item.sub_category_id !== qualification.sub_category_id) ];
        setCvRubricationsState(updatedQualifications);
        // localStorage.setItem('cv_rubrications_'+cvId, JSON.stringify(updatedQualifications))
    }

    const updateCv = (rubrication_items) => {
        dispatch(setShowLoader(true));
        const body = {
            rubrication_items,
        }
        axiosClientNode.post(`/cvs/${cvId}/update?type=job`, body).then(res => {
            if (res.status === 200) {
                dispatch(setShowLoader(false));
            }
        })
    }

    const handleSubmit = () => {
        const plusTextCv = cvQualifications.filter(el=>el.checked).map(el=>el.sub_category_name).join(', ');
        updateCv(cvRubricationsState)
        dispatch(setCvRubricationData(cvRubricationsState));
        dispatch(setJobId(''));
        dispatch(setJobTitle(''));
        dispatch(addJob([]));
        dispatch(addJobOptions([]));
        dispatch(setJobRubricationData([]));
        dispatch(setTrainingId(''));
        dispatch(setTrainingRubricationData([]));
    }

    const [isOpen, setIsOpen] = useState(true);


    return (
        <div className="py-4 w-full">


            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
                <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
                    <div className="flex flex-wrap items-center">
                        <div className="relative w-full max-w-full flex-grow flex-1 flex gap-2 items-center">
                            <i className="fa fa-user-check"></i>
                            <h2 className="text-blueGray-700 text-xl font-semibold">
                            {t('REQUIREMENT QUALIFICATIONS')}
                            </h2>
                            <Info  msg={t('List of Requirement qualifications recognizable in the JOB.')} />
                        </div>
            
                    </div>
                </div>
            </div>

            <div>
                <div className="rounded-t mb-0 px-4 py-4 bg-white">
                    <div className="flex justify-between gap-2 items-center">
                        <div className="relative w-full max-w-full flex-grow flex-1 flex gap-2 items-center">
                            <span className="text-semibold text-2xl">1</span>
                            <h2 className="text-blueGray-700 text-lg ">
                             {t('REQUIREMENT QUALIFICATIONS FOR CV MAPPING')}
                            </h2>
                            <Info  msg={t('The qualifications can be considered or ignored for CV mapping by simply selecting them.')} />
                        </div>
                        <button onClick={()=>setIsOpen(!isOpen)} > <i className={`fa text-xl ${isOpen ? "fa-chevron-down" : "fa-chevron-right"}`}></i></button>
            
                    </div>
                </div>
                {isOpen && <div className="w-full relative min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded" >
                    <div className="flex flex-col">
                        <div>
                            {canUpdate && <div className="px-4 flex gap-2 items-center">
                                <i className="fa fa-check text-green-500 text-lg"></i>
                                <p className="flex gap-2">
                                    {t('Use to map CV')}
                                    <Info  msg={t('Qualifications to be used for CV mapping.')} />
                                </p>
                            </div>}
                            <div className="p-4 flex gap-2 flex-wrap">
                                
                                {cvQualifications?.length > 0 && cvQualifications.filter(el=> el.checked).map((item)=> (<div className="flex gap-2 bg-indigo-200 px-4 py-2 rounded-full items-center" key={item.sub_category_id}>
                                    {item.sub_category_name}
                                    {canUpdate && <button className="text-xl text-red-500 pl-2" onClick={()=>handleSelectQualification(item, false)}><i className="fa fa-times"></i></button>}
                                </div>))}
                                
                            </div>
                        </div>

                        
                        {canUpdate && <div className="px-4 flex gap-2 items-center">
                        <i className="fa fa-times text-red-500 text-lg"></i>
                                <p className="flex gap-2">
                                    {t('Diselect before map CV')}
                                    <Info  msg={t('Qualifications that should not be used for CV mapping.')} />
                                </p>
                        </div>}
                        <div className="p-4 flex gap-2 flex-wrap">
                            {cvQualifications?.length > 0 && cvQualifications.filter(el=> !el.checked).map((item)=> (<div className="flex gap-2 bg-red-200 px-4 py-2 rounded-full items-center" key={item.sub_category_id}>
                                {item.sub_category_name}
                                {canUpdate && <button className="text-xl text-green-500 pl-2" onClick={()=>handleSelectQualification(item, true)}><i className="fa fa-check"></i></button>}
                            </div>))}
                            
                        </div>
                        {canUpdate && <div className="flex justify-center py-4">
                            <button className="bg-green-500 text-white font-semibold py-2 rounded text-lg px-8" onClick={handleSubmit}>{t('update job')}</button>
                        </div>}
                    </div>
                    
                </div>}
            </div>
        </div>
    )
}

export default ActualQualifications;